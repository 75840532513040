import { useState, KeyboardEvent } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { TextField, Icon } from 'components';
import moment from 'moment';
import { CompositionStatusType } from 'apis/medical';
import capitalize from 'lodash/capitalize';
import styles from './style.module.scss';
import { ProviderNotesAdditionalNoteProps, AdditionalNoteType } from './ProviderNotesReview.types';

export default function ProviderNotesAdditionalNote({
  id,
  title,
  text,
  hasPriorVersion,
  previousVersion,
  priorNote = '',
  soapNotes,
  user,
  form,
  bind,
  canAddAddendum,
  canAddInstruction,
  onStartEdit,
  onFinishEdit,
  onDeleteAdditionalNote,
}: ProviderNotesAdditionalNoteProps) {
  const [showPriorVersion, setShowPriorVersion] = useState(false);
  const [additionalNoteShow, setAdditionalNoteShow] = useState(false);
  const [additionalNoteEditing, setAdditionalNoteEditing] = useState(false);

  const handleStartEdit = (additionalNoteType: AdditionalNoteType) => {
    setAdditionalNoteEditing(!additionalNoteEditing);
    setAdditionalNoteShow(!additionalNoteShow);
    onStartEdit(additionalNoteType);
  };

  const handleFinishEdit = () => {
    setAdditionalNoteEditing(!additionalNoteEditing);
    if (!form.getField(id)) {
      setAdditionalNoteShow(!additionalNoteShow);
      onDeleteAdditionalNote(canAddAddendum ? AdditionalNoteType.ADDENDUM : AdditionalNoteType.INSTRUCTION);
    }
    onFinishEdit();
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'filled',
    InputProps: {
      disableUnderline: true,
      readOnly: !additionalNoteEditing,
    },
    InputLabelProps: {
      shrink: true,
    },
    placeholder: canAddAddendum
      ? 'Add any additional text you would like submitted alongside the provider’s note.'
      : 'How to improve this note...',
    multiline: true,
  };

  return (
    <Grid item xs={12}>
      <div className={styles.noteHeader}>
        <p className={styles.noteLabel}>{capitalize(title)}</p>
        {hasPriorVersion && (
          <p className={styles.showPriorVersion} onClick={() => setShowPriorVersion(!showPriorVersion)}>
            {showPriorVersion ? 'Hide' : 'Show'} Prior Version
          </p>
        )}
      </div>
      {showPriorVersion && (
        <div className={styles.priorVersion}>
          <p className={styles.priorVersionLabel}>
            Version {previousVersion.submission_count || 0}:{' '}
            {moment(previousVersion.meta?.lastUpdated || previousVersion.date_submitted).format('LLL')}
          </p>
          <p className={styles.priorVersionNote}>{priorNote}</p>
        </div>
      )}
      {hasPriorVersion && (
        <div className={styles.versionLabel}>
          <p className={styles.versionHighlight}>Version {soapNotes?.submission_count}:</p>&nbsp;
          {moment(soapNotes.meta?.lastUpdated || soapNotes.date_submitted).format('LLL')}
        </div>
      )}
      <p className={styles.noteText}>{text}</p>
      {[CompositionStatusType.Rejected, CompositionStatusType.Submitted].includes(
        soapNotes?.status as CompositionStatusType
      ) &&
        (!additionalNoteShow ? (
          <>
            {canAddAddendum && (
              <Button
                className={styles.addAdditionalNote}
                startIcon={<Icon name='add-circle' size={14} />}
                onClick={() => handleStartEdit(AdditionalNoteType.ADDENDUM)}>
                Add Addendum
              </Button>
            )}
            {canAddInstruction && (
              <Button
                className={styles.addAdditionalNote}
                disabled={soapNotes.zcc_entry}
                style={{ marginLeft: canAddAddendum ? 8 : 0 }}
                startIcon={<Icon name='chat-bubble' size={18} />}
                onClick={() => handleStartEdit(AdditionalNoteType.INSTRUCTION)}>
                Add Note for Provider
              </Button>
            )}
          </>
        ) : (
          <div className={styles.yourAdditionalNote}>
            <span>{canAddAddendum ? 'Your Addendum' : 'Your Note to the Provider'}</span>
            {!additionalNoteEditing && (
              <>
                <Icon name='edit-pencil' size={18} onClick={() => setAdditionalNoteEditing(!additionalNoteEditing)} />
                <Icon
                  name='trash'
                  size={18}
                  onClick={() => {
                    setAdditionalNoteShow(!additionalNoteShow);
                    setAdditionalNoteEditing(false);
                    form.resetField(id);
                    onDeleteAdditionalNote(
                      canAddAddendum ? AdditionalNoteType.ADDENDUM : AdditionalNoteType.INSTRUCTION
                    );
                  }}
                />
              </>
            )}
          </div>
        ))}
      {additionalNoteShow && (
        <>
          {additionalNoteEditing ? (
            <TextField
              {...bind(id)}
              className={styles.additionalNoteEditText}
              hiddenLabel
              autoFocus
              {...fieldProps}
              onBlur={handleFinishEdit}
              onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleFinishEdit();
                }
              }}
            />
          ) : (
            <p className={styles.additionalNoteText}>{form.getField(id)}</p>
          )}
          {canAddAddendum && (
            <p className={styles.reviewerInfo}>
              – {user?.pccAgentName} {moment().format('LL')}
            </p>
          )}
        </>
      )}
    </Grid>
  );
}

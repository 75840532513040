/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { MedicalApi, DocumentApi, ZrefApi, ZCCApi } from 'apis';
import { AddressType, InsuranceType } from 'apis/medical';
import { PractitionerType } from 'apis/practitioner';
import { ZccVAFacilityType } from 'apis/zcc';
import {
  ClientReferralRevocationReasonType,
  ClientReferralStatusType,
  ClientReferralType,
  ZRefAddressType,
} from 'apis/zref';
import { useState, useForm, useDebouncedCallback, useUIStore } from 'utils/hooks';
import Modal, { ModalPropType } from '../templates/Modal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import { FieldTitle, TextField, PhoneField, Checkbox, Icon } from 'components';
import PractitionerSearch from './PractitionerSearch';
import styles from './style.module.scss';
import { DocumentType } from 'types/document';
import capitalize from 'lodash/capitalize';
import useIsTestDataCreator from 'utils/hooks/useIsTestDataCreator';

export type AddReferralProps = ModalPropType & {
  referralFile?: File | null;
};

export type HiddenFieldsType = {
  condition_type?: string;
  consult_number?: string;
  initial_community_care?: {
    location?: {
      address?: ZRefAddressType;
      email?: string;
      name?: string;
      phone?: string;
    };
    name?: string;
    provider_name?: string;
    provider_npi?: string;
  };
  priority?: string;
  referring_provider?: {
    email?: string;
    emergency_contact?: string;
    family_name?: string;
    fax?: string;
    given_name?: Array<string>;
    group_practice_name?: string;
    group_practice_npi?: string;
    individual_practice_name?: string;
    individual_practice_npi?: string;
    phone?: string;
    specialty_type?: string;
  };
  requested_location?: {
    address?: ZRefAddressType;
    email?: string;
    name?: string;
    phone?: string;
  };
};

export default function AddReferral({
  referralFile: defaultReferralFile = null,
  onClose,
  scrollTop,
  modalProps,
}: AddReferralProps) {
  const isTestDataCreator = useIsTestDataCreator();
  const [loading, setLoading] = useState(true);
  const [icnChecking, setIcnChecking] = useState(false);
  const [icnValid, setIcnValid] = useState(false);
  const [facilities, setFacilities] = useState<Array<ZccVAFacilityType>>([]);
  const [bodyParts, setBodyParts] = useState<Array<string>>([]);
  const [, /*diagnosisCodes*/ setDiagnosisCodes] = useState<Array<string>>([]);
  const [patientRelationshipTypes, setPatientRelationshipTypes] = useState<Array<string>>([]);
  const [referralFile, setReferralFile] = useState<File | null>(defaultReferralFile);
  const [referralFileUploadedDocumentUrl, setReferralFileUploadedDocumentUrl] = useState<string | null>();
  const [referralFileError, setReferralFileError] = useState<string | null>();
  const [isReferralAvailable, setReferralAvailable] = useState<boolean | null>(null);
  const [documentationFiles, setDocumentationFiles] = useState<Array<File> | null>([]);
  const [selectedPractitioner, setSelectedPractitioner] = useState<PractitionerType>();
  const [hiddenFields, setHiddenFields] = useState<HiddenFieldsType>({});
  const [prefilledProviderNpi, setPrefilledProviderNpi] = useState<string>();
  const [matchingAddress, setMatchingAddress] = useState<AddressType>();
  const [needOfficeConfirm, setNeedOfficeConfirm] = useState<boolean>();
  const [regionValues, setRegionValues] = useState<Array<string>>([]);
  // const [addressObject, setAddressObject] = useState<AddressSuggestionType | null>();
  const { openAlert, openModal } = useUIStore();
  const { bind, form } = useForm({
    // referralNumber: 'SEOC-',
    // serviceLength: 60,
    // insurer: 'Optum',
    contactPreference: 'any',
    isPolicyHolder: true,
    bodyParts: [],
    diagnosisCode: null,
    icd10Codes: [],
    testData: isTestDataCreator,
  });
  const [numCodes, setNumCodes] = useState<Array<string>>([uuid()]);
  const [numBodyParts, setNumBodyParts] = useState<Array<string>>([uuid()]);
  const [numInsurances, setNumInsurances] = useState<Array<string>>([uuid()]);

  useEffect(() => {
    const promises = [
      ZCCApi.fetchAllVAFacilities(),
      ZrefApi.fetchBodyParts(),
      ZrefApi.fetchDiagnosisCodes(),
      ZrefApi.fetchPatientRelationshipTypes(),
    ];
    Promise.all(promises as any)
      .then((response: Array<any>) => {
        setFacilities(response[0]);
        setBodyParts(response[1]);
        setDiagnosisCodes(response[2]);
        setPatientRelationshipTypes(response[3]);
        const regions: string[] = response[0]
          .filter((facility: ZccVAFacilityType) => facility.vamc_facility_id)
          .map((facility: ZccVAFacilityType) => facility.vamc_facility_id.split('-')[0]);
        setRegionValues([...new Set(regions)].sort());
      })
      .catch((error) => {
        console.error('An error occurred while fetching the facilities, body parts or diagnosis codes', error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (form.touched['officeBased']) {
      form.validate();
    }
  }, [form, selectedPractitioner, matchingAddress]);

  const closeReferral = async () => {
    openModal({
      id: 'confirm',
      props: {
        title: 'Stop Referral Ingestion',
        description: `Are you sure you want to stop ingestion of this referral? All your progress will be lost.`,
        closable: false,
      },
      callback: async (response: any) => {
        if (response) {
          onClose?.();
        }
      },
    });
  };

  const checkReferralNumber = async (referralNumber: string) => {
    try {
      const response = await ZrefApi.fetchReferrals({ referralNumber });
      return response?.length === 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const checkReferralNumberHandler = async (referralNumber: any) => {
    setLoading(true);
    const isValidReferral = await checkReferralNumber(referralNumber);
    if (isValidReferral) {
      setReferralAvailable(true);
    } else {
      setReferralAvailable(false);
    }
    setLoading(false);
  };

  const checkIcnNumber = async (icn: any, skipPatientPrefill?: boolean) => {
    if (!icn || !icn.split(' ').join('')) {
      setIcnChecking(false);
      setIcnValid(false);
      return;
    }
    setIcnValid(false);
    setIcnChecking(true);

    try {
      const patient = await MedicalApi.fetchPatientByIcn(icn);
      if (patient) {
        if (patient?.removed === true) {
          openModal({
            id: 'info',
            props: {
              title: 'Patient Removed From Network',
              description: `The patient ${(patient?.given_names || []).join(' ')} ${
                patient?.family_name
              } linked to the ICN ${
                patient?.icn
              } was removed from network. No referrals can be ingested for that patient.`,
            },
            callback: () => {
              form.clearField('icn');
              setIcnValid(false);
            },
          });
        } else {
          if (skipPatientPrefill) {
            setIcnValid(true);
          } else {
            openModal({
              id: 'confirm',
              props: {
                title: 'ICN Linked To Existing Patient',
                description: `The ICN ${patient?.icn} is already linked to patient ${(patient?.given_names || []).join(
                  ' '
                )} ${patient?.family_name}. Do you wish to prefill this form using this patient's information?`,
                closable: false,
              },
              callback: async (response: any) => {
                if (response) {
                  const address = patient?.address?.[0] || {};
                  form.setFields({
                    edipi: patient?.edipi,
                    firstName: (patient?.given_names || []).join(' ').trim(),
                    familyName: patient?.family_name,
                    dateOfBirth: patient?.birth_date,
                    // ssn: patient?.ssn,
                    phone: patient?.phone,
                    address1: address?.line?.[0],
                    unit: (address?.line || []).slice(1).join(' '),
                    city: address?.city,
                    state: address?.state,
                    zip: address?.postal_code,
                    email: patient?.email,
                    gender:
                      patient?.gender &&
                      patient.gender !== 'unknown' &&
                      ['male', 'female', 'other'].includes(patient.gender)
                        ? patient.gender
                        : '',
                    hasCaregiver: !!patient?.caregiver,
                    caregiverFirstName: patient?.caregiver?.firstName,
                    caregiverLastName: patient?.caregiver?.lastName,
                    caregiverPhone: patient?.caregiver?.phone,
                    caregiverEmail: patient?.caregiver?.email,
                    caregiverRelationship:
                      patient?.caregiver?.relationship &&
                      (patientRelationshipTypes || []).includes(patient.caregiver.relationship)
                        ? patient?.caregiver?.relationship
                        : '',
                  });
                  setIcnValid(true);
                } else {
                  form.clearField('icn');
                  setIcnValid(false);
                }
              },
            });
          }
        }
      } else {
        setIcnValid(true);
      }
    } catch (error) {
      console.error(error);
      setIcnValid(false);
    }
    setIcnChecking(false);
  };

  const fileUploaded = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file: File = ((e.target as HTMLInputElement).files || [])[0];

    // clearing hidden fields
    setHiddenFields({});

    try {
      const referralDataFields = await ZrefApi.extractReferralDataFromPdf(file);
      const {
        assigned_provider,
        document_url,
        referral_number,
        medical_documentation,
        office_based,
        issue_date,
        expiration_date,
        facility_id,
        patient,
        service,
        chief_complaint,
        provisional_diagnosis,
        comorbidities,
        icd_10_codes,
        ...otherFields
      } = referralDataFields || {};
      const {
        address,
        caregivers,
        edipi,
        email,
        birth_date,
        family_name,
        gender,
        given_name,
        icn,
        phone,
        phone_only,
        phone_preferred,
        phone_business,
        phone_mobile,
        ssn,
      } = patient || {};
      const { line_1, line_2, locality, postal, region } = address || {};
      const assignedProviderNpi = assigned_provider?.individual_practice_npi;

      // Pick the extra supported fields to be passed behind the scenes
      const supportedHiddenFields: HiddenFieldsType = {
        consult_number: otherFields.consult_number,
        priority: otherFields.priority,
        initial_community_care: otherFields.initial_community_care,
        referring_provider: otherFields.referring_provider,
        requested_location: otherFields.requested_location,
      };

      const supportedHiddenFieldsNonNull = Object.fromEntries(
        Object.entries(supportedHiddenFields).filter(([, v]) => v != null)
      );

      setHiddenFields(supportedHiddenFieldsNonNull);

      const prefilledService = service;
      const { charges, duration } = prefilledService || {};

      const prefilledCaregiver = caregivers?.[0];
      const {
        email: caregiverEmail,
        family_name: caregiverFamilyName,
        given_name: caregiverFirstName,
        phone: caregiverPhone,
        relation_to_patient: caregiverRelationship,
      } = prefilledCaregiver || {};

      let prefilledContactPreference = '';
      if (phone_preferred) prefilledContactPreference = 'phonePreferred';
      if (phone_only) prefilledContactPreference = 'phoneOnly';

      // setting document url
      setReferralFileUploadedDocumentUrl(document_url);

      // Checking referral number
      form.setField('referralNumber', referral_number);
      const isReferralValid = await checkReferralNumber(referral_number);
      if (!isReferralValid) {
        setReferralAvailable(false);
        setLoading(false);
        return;
      }
      setReferralAvailable(true);

      const prefilledFacilityId = facilities.find((f) => f.vamc_facility_id === facility_id)?.vamc_facility_id || '';

      form.setFields({
        edipi: edipi?.trim(),
        icn: icn?.trim(),
        firstName: (given_name || []).join(' ').trim(),
        familyName: family_name?.trim(),
        dateOfBirth: birth_date,
        ssn: ssn?.trim(),
        phone: phone?.trim(),
        phoneWork: phone_business?.trim(),
        phoneMobile: phone_mobile?.trim(),
        address1: line_1?.trim(),
        unit: line_2?.trim(),
        city: locality?.trim(),
        state: region?.trim(),
        zip: postal?.trim(),
        email: email?.trim(),
        gender: gender && gender !== 'unknown' && ['male', 'female', 'other'].includes(gender) ? gender : '',
        nbServicesTotal: charges,
        serviceLength: ['30', '60'].includes(duration) ? duration : null,
        documentation: medical_documentation,
        officeBased: office_based,
        facilityId: prefilledFacilityId,
        vaRegion: prefilledFacilityId.split('-')[0],
        issueDate: issue_date,
        expirationDate: expiration_date,
        hasCaregiver: !!prefilledCaregiver,
        caregiverFirstName,
        caregiverFamilyName,
        caregiverPhone,
        caregiverEmail,
        caregiverRelationship:
          caregiverRelationship && (patientRelationshipTypes || []).includes(caregiverRelationship)
            ? caregiverRelationship
            : '',
        bodyParts: [],
        contactPreference: prefilledContactPreference,
        chiefComplaint: chief_complaint,
        provisionalDiagnosis: provisional_diagnosis,
        comorbidities,
        conditionType: otherFields.condition_type,
      });

      // prefilling in-office npi if available
      if (office_based && assignedProviderNpi) {
        setPrefilledProviderNpi(assignedProviderNpi);
      }
      // Setting icd_10_codes
      icd_10_codes?.forEach((code: string) => {
        const codeId = uuid();
        form.setField(`icd10code-${codeId}`, code);
        setNumCodes([codeId]); // not merging with current value since it is a placeholder value and would lead to field 1 empty and field 2 prefilled
      });

      await checkIcnNumber(icn, true);

      // adding impression
      // if (values.bodyParts?.length > 0 && values.diagnosisCode) {
      //   referralData.impressions = [
      //     {
      //       body_parts: values.bodyParts,
      //       // diagnosis_code: values.diagnosisCode
      //     },
      //   ];
      // }

      // if (allIcd10Codes?.length > 0) {
      //   referralData.icd_10_codes = allIcd10Codes;
      // }

      // Without timeout, the errors aren't triggered. Once we refactor to use Formik, this
      setTimeout(() => {
        form.validate();
        setLoading(false);
      });
    } catch (extractError) {
      console.error(extractError);
      setReferralFile(file);
      setLoading(false); // Put the set loading here, else it will be triggered before validate has completed, which bugs the screen
    }
    setReferralFile(file);
    setReferralFileError(null);
  };

  const documentationFileUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = (e.target as HTMLInputElement).files || [];
    setDocumentationFiles([...(documentationFiles || []), ...files]);
  };

  const deleteDocumentationFile = (fileIndex: number) => {
    const updatedFiles = [...(documentationFiles || [])];
    updatedFiles.splice(fileIndex, 1);
    setDocumentationFiles(updatedFiles);
  };

  const refNumberHandler = useDebouncedCallback(checkReferralNumberHandler, 1000);
  const icnNumberHandler = useDebouncedCallback(checkIcnNumber, 1000);

  // const addressSelected = (address: AddressSuggestionType) => {
  //   form.setField('address', address?.fullAddress); // BUG: doesn't take latest values
  //   setAddressObject(address);
  // }

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const isAhfReferral = useMemo(() => {
    return form.values.referralType === 'AHF';
  }, [form.values.referralType]);

  const resetForm = () => {
    setLoading(false);
    setIcnChecking(false);
    setIcnValid(false);
    setReferralFile(null);
    setReferralFileUploadedDocumentUrl(null);
    setReferralFileError(null);
    setReferralAvailable(null);
    setDocumentationFiles([]);
    setSelectedPractitioner(undefined);
    setHiddenFields({});
    setPrefilledProviderNpi(undefined);
    setMatchingAddress(undefined);
    setNeedOfficeConfirm(undefined);
    setNumCodes([uuid()]);
    setNumBodyParts([uuid()]);
    const insuranceId = uuid();
    setNumInsurances([insuranceId]);
    const referralType = form.values.referralType;
    form.reset();
    form.setField('referralType', referralType);
    if (referralType === 'AHF') {
      form.setField('serviceLength', 60);
      form.setField(`priority-${insuranceId}`, 'primary');
      form.setField(`insurance-${insuranceId}`, 'ahf');
    }
  };

  useEffect(() => {
    resetForm();
  }, [isAhfReferral]);

  const handleAddInsurance = () => {
    const newInsuranceId = uuid();
    if (numInsurances.length >= 1) {
      form.setField(`priority-${newInsuranceId}`, 'Secondary');
      form.setField(`subscriberId-${newInsuranceId}`, form.getField('ahf_member_id'));
    }
    setNumInsurances([...numInsurances, newInsuranceId]);
  };

  const onSubmit = async (values: any) => {
    console.log(values);
    setReferralFileError(!referralFile ? 'Please upload a pdf' : null);
    if (!isAhfReferral && !referralFile) {
      form.setFormError('Please fix the errors below');
      scrollTop();
      return;
    }

    // making sure user doesn't submit while icn is checking
    if (!isAhfReferral && icnChecking) {
      form.setFormError('Please wait until ICN has finished loading');
      scrollTop();
      return;
    }

    // making sure icn is valid
    if (!isAhfReferral && !icnValid) {
      form.setFormError('ICN is invalid or not available');
      scrollTop();
      return;
    }

    // uploading all files
    const uploadPromises: Array<any> = [];
    let useLocalUploadedFile = false;
    let referralPdfUrl = '';
    let associatedDocumentsUrls: string[] = [];
    if (!isAhfReferral) {
      if (!referralFileUploadedDocumentUrl) {
        uploadPromises.push(DocumentApi.uploadDocument(referralFile as File));
        useLocalUploadedFile = true;
      }

      documentationFiles?.forEach((f) => {
        uploadPromises.push(DocumentApi.uploadDocument(f as File));
      });

      let uploadedDocumentsUrl = null;
      try {
        const uploadedFiles = await Promise.all(uploadPromises);
        uploadedDocumentsUrl = uploadedFiles?.map((f: any) => f?.location);
      } catch (error) {
        form.setFormError(
          'An error occurred while uploading either the referral file or any additional documentation files.'
        );
        scrollTop();
        console.error(error);
        return;
      }
      referralPdfUrl = useLocalUploadedFile ? uploadedDocumentsUrl[0] : referralFileUploadedDocumentUrl; // referral pdf is always first added to promise array
      associatedDocumentsUrls = [...uploadedDocumentsUrl];
      if (useLocalUploadedFile) {
        associatedDocumentsUrls.shift(); // removing referral pdf and keeping only the rest
      }
    }

    // Getting insurer from va region

    const insurer: string = capitalize(facilities.filter((f) => f.vamc_facility_id === values.facilityId)[0]?.insurer);
    const allIcd10Codes: Array<string> = [];
    numCodes.forEach((codeSuffix: any) => {
      const val = values[`icd10code-${codeSuffix}`];
      if (val) allIcd10Codes.push(val);
    });
    const allBodyParts: Array<string> = [];
    numBodyParts.forEach((bpSuffix: any) => {
      const val = values[`bodyPart-${bpSuffix}`];
      if (val) allBodyParts.push(val);
    });

    const allInsurances: Array<InsuranceType> = [];
    numInsurances.forEach((insSuffix: any) => {
      const priority = values[`priority-${insSuffix}`];
      const insurerValue = values[`insurance-${insSuffix}`];
      const subscriberId = values[`subscriberId-${insSuffix}`];
      allInsurances.push({ priority: priority, insurer: insurerValue, subscriber_id: subscriberId });
    });

    try {
      // TODO: use the policyHolder values (policyHolderFirstName, policyHolderLastName, policyHolderMiddleName, policyHolderDateOfBirth)
      let referralData: ClientReferralType = {
        referral_number: values.referralNumber,
        type: values.referralType,
        issue_date: values.issueDate,
        expiration_date: values.expirationDate,
        facility_id: values.facilityId,
        insurer: isAhfReferral ? 'ahf' : insurer,
        medical_documentation: values.documentation,
        office_based: values.officeBased || false,
        document_url: referralPdfUrl || '',
        patient: {
          address: {
            line_1: values.address1 || '',
            line_2: values.unit,
            locality: values.city || '',
            postal: values?.zip || '',
            region: values?.state || '',
          },
          icn: values.icn,
          edipi: values.edipi || null,
          given_name: (values.firstName || '').trim().split(' '),
          family_name: values.familyName.trim(),
          birth_date: values.dateOfBirth,
          ...(values.ssn?.length > 0 ? { ssn: values.ssn } : {}),
          phone: values.phone,
          phone_business: values.phoneWork,
          phone_mobile: values.phoneMobile,
          phone_only: values.contactPreference === 'phoneOnly',
          phone_preferred: values.contactPreference === 'phonePreferred',
          email: values.email || null,
          gender: values.gender || 'unknown',
          ...(isAhfReferral ? { ahf_member_id: values.ahf_member_id || '' } : {}),
        },
        services: [
          {
            charges: Number(values.nbServicesTotal),
            duration: Number(values.serviceLength),
            ...(isAhfReferral
              ? {
                  description: String(values.nbServicesDescription),
                  duration_follow_up: Number(values.nbServiceDurationFollow),
                  duration_complex_follow_up: Number(values.nbServiceDurationCpxFollow),
                  duration_initial: Number(values.nbServiceDurationInitial),
                }
              : {}),
          },
        ],
        chief_complaint: values.chiefComplaint,
        provisional_diagnosis: values.provisionalDiagnosis,
        icd_10_codes: allIcd10Codes,
        body_parts: allBodyParts,
        test_data: !!values.testData,
        ...(values.conditionType ? { condition_type: values.conditionType } : {}),
        ...(isAhfReferral ? { insurance: allInsurances } : {}),
      };
      // conditionally add comorbidities
      if (values.comorbidities) {
        referralData.comorbidities = values.comorbidities;
      }
      if (values.revoked) {
        referralData.status = ClientReferralStatusType.Revoked;
        referralData.revocation_reason = ClientReferralRevocationReasonType.NonActionableByZeel;
      }

      // conditionally add associated documents
      if (associatedDocumentsUrls.length > 0) {
        referralData.associated_documents = (documentationFiles || []).map((document, i) => ({
          description: document.name,
          url: associatedDocumentsUrls[i],
          type: 'medical-document',
        })) as Array<{ description: string; url: string; type: DocumentType }>;
      }

      // adding caregiver
      if (values.hasCaregiver) {
        referralData.patient.caregivers = [
          {
            email: values.caregiverEmail,
            family_name: values.caregiverLastName,
            given_name: [values.caregiverFirstName],
            phone: values.caregiverPhone,
            relation_to_patient: values.caregiverRelationship,
            // gender: values.caregiverGender,
            // birth_date: values.caregiverBirthdate,
          },
        ];
      }

      if (values.officeBased && selectedPractitioner) {
        referralData.assigned_provider = {
          individual_practice_npi: selectedPractitioner?.npi,
        };

        if (matchingAddress) {
          hiddenFields.requested_location = {
            ...(hiddenFields.requested_location || {}),
            address: {
              line_1: matchingAddress.line?.[0],
              line_2: matchingAddress.line?.[1],
              locality: matchingAddress.city,
              postal: matchingAddress.postal_code,
              region: matchingAddress.state,
            },
          };
        }
      }

      // Adding extra hidden fields to the referral data
      referralData = {
        ...referralData,
        ...(hiddenFields || {}),
      };

      const createdReferral = await ZrefApi.createReferral(referralData);
      openAlert({ title: `Referral #${values.referralNumber} Submitted` });
      if (onClose) onClose?.(createdReferral);
    } catch (error: any) {
      // Handling of referral ingestion error related to patient RFN
      if (
        error?.response?.data?.status === 409 &&
        error?.response?.data?.detail === 'Patient RFN. Referral Rejected.'
      ) {
        form.setFormError('Patient had a previous referral returned as per T+S. Do not ingest at this time.');
      } else {
        form.setFormError(`An error occurred: ${error?.response?.data?.detail || ''}`);
      }
      scrollTop();
    }
  };

  const formDisabled =
    !isReferralAvailable ||
    !!form.errors['referralNumber'] ||
    !form.values['referralNumber'] ||
    (!referralFile && !isAhfReferral) ||
    (!!referralFileError && !isAhfReferral);
  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
    disabled: formDisabled,
  };
  const referralTypeStyle = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={form.formError}
      title='Add New Referral'
      description={
        !isAhfReferral ? (
          <>
            Start by uploading an SEOC Referral PDF,
            <br /> then enter referral data below.
          </>
        ) : (
          <>Enter referral data below.</>
        )
      }
      className={styles.base}
      backdropClose={false}
      warnBeforeClosing
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={
        formDisabled
          ? []
          : [
              { label: 'Cancel', action: 'close' },
              { label: 'Save', action: 'submit' },
            ]
      }>
      <div>
        <Grid className={styles.topFields} container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <TextField select label='Referral' {...referralTypeStyle} {...bind('referralType', { required: true })}>
              <MenuItem value='SEOC'>VA</MenuItem>
              <MenuItem value='AHF'>AHF</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={4} />
          {form.values.referralType === 'SEOC' && (
            <>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <input
                  accept='application/pdf'
                  onChange={fileUploaded}
                  style={{ display: 'none' }}
                  id='upload-pdf'
                  type='file'
                />
                {!referralFile && (
                  <label htmlFor='upload-pdf'>
                    <Button className={styles.button} variant='outlined' size='large' color='primary' component='span'>
                      Upload Referral PDF
                    </Button>
                  </label>
                )}
                {referralFile && <Icon name='pdf' size={62} />}
                {referralFile && referralFile.name && <p className={styles.successHelperText}>{referralFile?.name}</p>}
                {referralFileError && <p className={styles.fileError}>{referralFileError}</p>}
              </Grid>
              <Grid item xs={4} />
            </>
          )}
          <Grid item xs={4} />
          <Grid item xs={4}>
            <TextField
              {...bind('referralNumber', {
                required: true,
                onChange: refNumberHandler.callback,
              })}
              {...(isReferralAvailable === false ? { error: 'This referral # already exists. Please re-check.' } : {})}
              label='Referral Number'
              {...fieldProps}
              disabled={false}
            />
            {isReferralAvailable && <p className={styles.successHelperText}>This referral # is available.</p>}
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </div>

      {!formDisabled && (
        <>
          <div>
            <Grid className={styles.veteranFields} container spacing={3}>
              <Grid item xs={12}>
                <FieldTitle icon='orientation-card'>{isAhfReferral ? 'Personal' : 'Veteran'} Information</FieldTitle>
              </Grid>
              {!isAhfReferral && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('icn', {
                        required: true,
                        onChange: (arg: any) => {
                          setIcnChecking(true);
                          setIcnValid(false);
                          icnNumberHandler.callback(arg);
                        },
                      })}
                      label='Integration Control Number (ICN)'
                      autoFocus
                      {...fieldProps}
                      {...(icnChecking
                        ? {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <HourglassEmpty />
                                </InputAdornment>
                              ),
                            },
                          }
                        : {})}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('edipi', { required: false })}
                      label='EDIPI number (Optional)'
                      {...fieldProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <TextField {...bind('firstName', { required: true })} label='First Name' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...bind('familyName', { required: true })} label='Last Name' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('dateOfBirth', {
                    required: true,
                    validate: (val: any) => {
                      const momentDate = moment(val);
                      if (val && momentDate && momentDate.isValid() && moment().diff(momentDate, 'years') > 120) {
                        return 'The date appears to be invalid, verify that it was entered properly';
                      }
                      return null;
                    },
                  })}
                  label='Date of Birth'
                  type='date'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('ssn', { required: !isAhfReferral })}
                  inputProps={{ maxLength: 9 }}
                  label='SSN'
                  {...fieldProps}
                />
              </Grid>
              {isAhfReferral && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('ahf_member_id', { required: true })}
                      inputProps={{ maxLength: 9 }}
                      label='AHF Member ID'
                      {...fieldProps}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <PhoneField {...bind('phone', { required: true })} label='Primary Phone Number' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('contactPreference', { required: !isAhfReferral })}
                  select
                  label='Contact Method'
                  {...fieldProps}>
                  <MenuItem value=''>-</MenuItem>
                  <MenuItem value='any'>Any</MenuItem>
                  <MenuItem value='phoneOnly'>Phone Only</MenuItem>
                  <MenuItem value='phonePreferred'>Phone Preferred</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <PhoneField {...bind('phoneMobile')} label='Mobile Phone Number (Optional)' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <PhoneField {...bind('phoneWork')} label='Work Phone Number (Optional)' {...fieldProps} />
              </Grid>

              <Grid item xs={6}>
                <TextField {...bind('address1', { required: true })} label='Address' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...bind('unit', { required: false })} label='Unit Number (Optional)' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...bind('city', { required: true })} label='City' {...fieldProps} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('state', { required: true })}
                  inputProps={{ maxLength: 2 }}
                  label='State'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('zip', { required: true })}
                  inputProps={{ maxLength: 5 }}
                  label='Zip'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('email', { required: false })}
                  type='email'
                  label='Email Address (Optional)'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField {...bind('gender', { required: !isAhfReferral })} select label='Gender' {...fieldProps}>
                  <MenuItem value=''>-</MenuItem>
                  <MenuItem value='male'>Male</MenuItem>
                  <MenuItem value='female'>Female</MenuItem>
                  <MenuItem value='other'>Other</MenuItem>
                </TextField>
              </Grid>
            </Grid>
            {isAhfReferral && (
              <>
                <Grid className={styles.policyHolderFields} container spacing={3}>
                  <Grid item xs={12}>
                    <FieldTitle icon='pad-with-star'>Insurance Information</FieldTitle>
                  </Grid>
                  {numInsurances.map((insuranceId, insuranceIndex) => {
                    return (
                      <>
                        <Grid item xs={4}>
                          <TextField
                            {...bind(`priority-${insuranceId}`, { required: isAhfReferral })}
                            label={`Priority ${insuranceIndex > 0 ? insuranceIndex + 1 : ''}`}
                            {...fieldProps}
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            {...bind(`insurance-${insuranceId}`, { required: isAhfReferral })}
                            label={`Insurance ${insuranceIndex > 0 ? insuranceIndex + 1 : ''}`}
                            {...fieldProps}
                            inputProps={{ readOnly: numInsurances[0] == insuranceId }}
                          />
                        </Grid>
                        <Grid item xs={4} style={{ position: 'relative' }}>
                          <TextField
                            {...bind(`subscriberId-${insuranceId}`, { required: isAhfReferral })}
                            label={`Subscriber ID ${insuranceIndex > 0 ? insuranceIndex + 1 : ''}`}
                            {...fieldProps}
                          />
                          {insuranceIndex > 0 && (
                            <Icon
                              className={styles.icd10TrashIcon}
                              size={24}
                              name='x'
                              onClick={() => {
                                form.deleteField(`insurance-${insuranceId}`);
                                setNumInsurances([...numInsurances].filter((x: any) => x !== insuranceId));
                              }}
                            />
                          )}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item xs={12}>
                    <p className={styles.addDiagnosisLink} onClick={() => handleAddInsurance()}>
                      <Icon className={styles.icon} size={18} name='add-circle' />
                      Add Additional Insurance
                    </p>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid className={styles.caregiverFields} container spacing={3}>
              <Grid item xs={12}>
                <Checkbox {...bind('hasCaregiver')} label='Patient has a caregiver' />
              </Grid>
              {form.getField('hasCaregiver') && (
                <>
                  <Grid item xs={12}>
                    <FieldTitle icon='users'>Caregiver Information</FieldTitle>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('caregiverFirstName', { requiredIf: (values: any) => values['hasCaregiver'] })}
                      label='First Name'
                      {...fieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('caregiverLastName', { requiredIf: (values: any) => values['hasCaregiver'] })}
                      label='Last Name'
                      {...fieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <PhoneField
                      {...bind('caregiverPhone', { requiredIf: (values: any) => values['hasCaregiver'] })}
                      label='Phone Number'
                      {...fieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('caregiverEmail', { requiredIf: (values: any) => values['hasCaregiver'] })}
                      label='Email Address'
                      {...fieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('caregiverRelationship', { requiredIf: (values: any) => values['hasCaregiver'] })}
                      select
                      label='Caregiver’s Relationship to Patient'
                      {...fieldProps}>
                      {patientRelationshipTypes?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid className={styles.referralFields} container spacing={3}>
              <Grid item xs={12}>
                <FieldTitle icon='pad-with-star'>Referral Information</FieldTitle>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('nbServicesTotal', {
                    required: true,
                    validate: (val: any) => {
                      if (Number(val) <= 0) return 'Cannot be lower than 0';
                      return null;
                    },
                  })}
                  type='number'
                  label='# of Total Authorized Services'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('serviceLength', { required: !isAhfReferral })}
                  select
                  label='Service Length (in minutes)'
                  {...fieldProps}>
                  {!isAhfReferral && <MenuItem value={30}>30 minutes</MenuItem>}
                  <MenuItem value={60}>60 minutes</MenuItem>
                </TextField>
              </Grid>
              {isAhfReferral && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('nbServicesDescription', {
                        required: false,
                      })}
                      type='string'
                      label='Desctiption'
                      {...fieldProps}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('nbServiceDurationCpxFollow', { required: false })}
                      select
                      label='Duration Complex Follow Up'
                      {...fieldProps}>
                      <MenuItem value={30}>30 minutes</MenuItem>
                      <MenuItem value={60}>60 minutes</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('nbServiceDurationFollow', { required: false })}
                      select
                      label='Duration Follow Up'
                      {...fieldProps}>
                      <MenuItem value={30}>30 minutes</MenuItem>
                      <MenuItem value={60}>60 minutes</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      {...bind('nbServiceDurationInitial', { required: false })}
                      select
                      label='Duration Initial'
                      {...fieldProps}>
                      <MenuItem value={30}>30 minutes</MenuItem>
                      <MenuItem value={60}>60 minutes</MenuItem>
                    </TextField>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <TextField
                  {...bind('issueDate', {
                    required: true,
                    validate: (val: any) => {
                      if (val && moment().diff(moment(val, 'YYYY-MM-DD')) < 0) return 'Cannot be greater than today';
                      return null;
                    },
                  })}
                  label='Issue Date'
                  type='date'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('expirationDate', {
                    required: true,
                    validate: (val: any, values: any) => {
                      if (
                        val &&
                        values.issueDate &&
                        moment(val, 'YYYY-MM-DD').diff(moment(values.issueDate, 'YYYY-MM-DD')) < 0
                      )
                        return 'Needs to be greater than the issue date';
                      return null;
                    },
                  })}
                  label='Expiration Date'
                  type='date'
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...bind('conditionType', { required: !isAhfReferral })}
                  select
                  label='SEOC Type'
                  {...fieldProps}>
                  <MenuItem value='Chronic'>Chronic</MenuItem>
                  <MenuItem value='Acute'>Acute</MenuItem>
                  <MenuItem value='Initial Care'>Initial Care</MenuItem>
                  <MenuItem value='Continuation Care'>Continuation Care</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  {...bind('officeBased', {
                    validate: (val: any) => {
                      if (val) {
                        if (!selectedPractitioner) {
                          return 'Please search for the provider.';
                        } else if (!selectedPractitioner.office_based_address?.length) {
                          return 'No office location exists for this provider. Please contact Trust & Safety directly for scheduling.';
                        } else if (!matchingAddress) {
                          return 'Please choose one of the provider office locations above.';
                        }
                      }
                      return null;
                    },
                  })}
                  error=''
                  label='This is an office-based referral'
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox {...bind('revoked')} error='' label='Revoked - Non-Actionable by Zeel' />
              </Grid>
              {form.getField('officeBased') && (
                <>
                  <Grid item xs={12}>
                    <PractitionerSearch
                      npi={prefilledProviderNpi}
                      showBorder
                      error={form.getFieldError('officeBased')}
                      requestedLocation={hiddenFields.requested_location?.address}
                      onMatchAddressChange={(address, needConfirm) => {
                        setMatchingAddress(address);
                        setNeedOfficeConfirm(needConfirm);
                      }}
                      onPractitionerSelected={(prac) => {
                        if (prac) {
                          setSelectedPractitioner(prac);
                        } else {
                          setSelectedPractitioner(undefined);
                          setMatchingAddress(undefined);
                          setNeedOfficeConfirm(undefined);
                        }
                      }}
                    />
                  </Grid>
                  {needOfficeConfirm && (
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <Checkbox
                        {...bind('continueOfficeBased', {
                          validate: (val: any) => {
                            if (form.getField('officeBased') && !val && needOfficeConfirm) {
                              return 'Please indicate if you want to continue using indicated provider';
                            }
                            return null;
                          },
                        })}
                        error={form.getFieldError('continueOfficeBased')}
                        label='Continue using indicated provider anyway'
                      />
                      <p className={styles.orDivider}>----or----</p>
                      <div className={styles.alert} onClick={closeReferral}>
                        <Icon name='x-circle' size={24} />
                        <span>Stop adding this referral and close</span>
                      </div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            {!isAhfReferral && (
              <Grid className={styles.facilityFields} container spacing={3}>
                <Grid item xs={12}>
                  <FieldTitle icon='location'>Referring VA Facility</FieldTitle>
                </Grid>
                <Grid item xs={6}>
                  {/* <TextField {...bind('insurer', { required: true })} select label="Insurance Identifier" {...fieldProps}>
                  <MenuItem value="Optum">Optum</MenuItem>
                  <MenuItem value="Triwest">Triwest</MenuItem>
                </TextField> */}
                  <TextField
                    {...bind('vaRegion', { required: true, onChange: () => form.setField('facilityId', null) })}
                    select
                    label='Network'
                    {...fieldProps}>
                    {regionValues.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    {...bind('facilityId', { required: true })}
                    select
                    label='VA Facility'
                    {...fieldProps}
                    disabled={!form.getField('vaRegion')}>
                    {(facilities || [])
                      .filter((f: ZccVAFacilityType) => {
                        const selectedRegionNumber = form.getField('vaRegion');
                        if (selectedRegionNumber) {
                          return (f?.vamc_facility_id || '')[0] + '' === (selectedRegionNumber || '') + '';
                        }
                        return true;
                      })
                      .map((facility: ZccVAFacilityType) => {
                        const { vamc_facility_id, vamc_name } = facility;
                        return (
                          <MenuItem key={vamc_facility_id} value={vamc_facility_id}>
                            {vamc_facility_id} - {vamc_name}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
              </Grid>
            )}
            <Grid className={styles.documentationFields} container spacing={3}>
              <Grid item xs={12}>
                <FieldTitle icon='orientation-card'>Medical Documentation</FieldTitle>
              </Grid>
              <Grid item xs={12}>
                {(documentationFiles || []).length > 0 && (
                  <p className={styles.documentationFileContainerLabel}>
                    {(documentationFiles || []).length} File{(documentationFiles || []).length > 1 ? 's' : ''}
                  </p>
                )}
                <div className={styles.documentationFilesContainer}>
                  {documentationFiles?.map((file, fileIndex) => {
                    const { name } = file;
                    return (
                      <div key={`${name}-${fileIndex}`} className={styles.documentationFile}>
                        {name}
                        <Icon
                          className={styles.documentationFileDeleteIcon}
                          name='trash'
                          size={24}
                          onClick={() => deleteDocumentationFile(fileIndex)}
                        />
                      </div>
                    );
                  })}
                </div>
                <input
                  accept='application/pdf'
                  onChange={documentationFileUploaded}
                  style={{ display: 'none' }}
                  id='upload-documentation'
                  type='file'
                  multiple
                />
                <label htmlFor='upload-documentation'>
                  <Button className={styles.button} variant='outlined' size='small' color='primary' component='span'>
                    Add Files
                  </Button>
                </label>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  {...bind('chiefComplaint', { required: !isAhfReferral })}
                  label='Chief Complaint'
                  placeholder='e.g "Back pain"'
                  {...fieldProps}
                  MenuProps={{ getContentAnchorEl: () => null }}>
                  {bodyParts?.map((bodyPart) => (
                    <MenuItem key={bodyPart} value={bodyPart}>
                      {bodyPart}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={12}>
                <TextField
                  {...bind('provisionalDiagnosis', { required: !isAhfReferral })}
                  label='Provisional Diagnosis'
                  multiline
                  rows={5}
                  {...fieldProps}
                />
              </Grid>

              {/* <Grid item xs={6}>
                <TextField
                  {...bind('bodyParts', { required: true })}
                  select
                  SelectProps={{ multiple: true }}
                  label='Body Parts'
                  {...fieldProps}
                  MenuProps={{ getContentAnchorEl: () => null }}>
                  {bodyParts?.map((bodyPart) => (
                    <MenuItem key={bodyPart} value={bodyPart}>
                      {bodyPart}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}
              {/* <Grid item xs={6} /> */}
              {/* <Grid item xs={6}> */}
              {/* <TextField {...bind('diagnosisCode', { required: true })} select label="Diagnosis Code" {...fieldProps}>
                  {diagnosisCodes?.map(diagnosisCode => <MenuItem value={diagnosisCode}>{diagnosisCode}</MenuItem>)}
                </TextField> */}

              {numBodyParts.map((bpId, i) => {
                return (
                  <>
                    <Grid item xs={6} style={{ position: 'relative' }}>
                      {/* <TextField
                        {...bind(`bodyPart-${bp}`, { required: true })}
                        label={`Body Part ${i === 0 ? '' : i + 1}`}
                        {...fieldProps}
                      /> */}
                      <TextField
                        {...bind(`bodyPart-${bpId}`, { required: !isAhfReferral })}
                        select
                        label={`Body Part ${i === 0 ? '' : i + 1}`}
                        {...fieldProps}
                        MenuProps={{ getContentAnchorEl: () => null }}>
                        {bodyParts?.map((bodyPart) => (
                          <MenuItem key={bodyPart} value={bodyPart}>
                            {bodyPart}
                          </MenuItem>
                        ))}
                      </TextField>
                      {i > 0 && (
                        <Icon
                          className={styles.icd10TrashIcon}
                          size={24}
                          name='x'
                          onClick={() => {
                            form.deleteField(`bodyPart-${bpId}`);
                            setNumBodyParts([...numBodyParts].filter((x: any) => x !== bpId));
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} />
                  </>
                );
              })}
              {/* </Grid> */}
              <Grid item xs={12}>
                <p className={styles.addDiagnosisLink} onClick={() => setNumBodyParts([...numBodyParts, uuid()])}>
                  <Icon className={styles.icon} size={18} name='add-circle' />
                  Add Additional Body Part
                </p>
              </Grid>

              {numCodes.map((codeId, i) => {
                return (
                  <>
                    <Grid item xs={6} style={{ position: 'relative' }}>
                      <TextField
                        {...bind(`icd10code-${codeId}`, { required: true })}
                        label={`Diagnosis Code ${i === 0 ? '' : i + 1}`}
                        {...fieldProps}
                      />
                      {i > 0 && (
                        <Icon
                          className={styles.icd10TrashIcon}
                          size={24}
                          name='x'
                          onClick={() => {
                            form.deleteField(`icd10code-${codeId}`);
                            setNumCodes([...numCodes].filter((x: any) => x !== codeId));
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6} />
                  </>
                );
              })}
              {/* </Grid> */}
              <Grid item xs={12}>
                <p className={styles.addDiagnosisLink} onClick={() => setNumCodes([...numCodes, uuid()])}>
                  <Icon className={styles.icon} size={18} name='add-circle' />
                  Add Additional Diagnosis Code
                </p>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  {...bind('comorbidities', { required: !isAhfReferral })}
                  label='Patient History / Clinical Findings / Diagnosis (Co-Morbidities)'
                  multiline
                  rows={5}
                  {...fieldProps}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...bind('documentation', { required: false })}
                  label='Additional Medical Documentation (Optional)'
                  multiline
                  rows={5}
                  {...fieldProps}
                />
                <p className={styles.piiWarning}>
                  Do not include PII. Text in this field will be shown to Providers prior to accepting the booking.
                </p>
              </Grid>
              {isTestDataCreator && (
                <Grid item xs={12}>
                  <Checkbox {...bind('testData')} error='' label='This is test data' />
                </Grid>
              )}
            </Grid>
          </div>
        </>
      )}
    </Modal>
  );
}

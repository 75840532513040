import { useEffect } from 'react';
import Iframe from 'react-iframe';
import { useState, useAuth } from 'utils/hooks';
import { SectionLayout } from 'components';
import styles from './style.module.scss';

export default function TableTest() {
  const [appointmentId, setAppointmentId] = useState<any>();
  const auth = useAuth();

  useEffect(() => {
    setTimeout(() => {
      setAppointmentId('2163708');
    }, 1000);
  }, []);

  return (
    <SectionLayout>
      <div className={styles.base}>
        <Iframe
          url={`${process.env.REACT_APP_OG_API}/cms/${auth?.user?.memberId}/appointment/listing?appt_id=${appointmentId}`}
          width='100%'
          height='400px'
          id=''
          className=''
          display='block'
          position='relative'
        />
      </div>
    </SectionLayout>
  );
}

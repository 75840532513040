import React from 'react';
import moment from 'moment';
import { MedicalApi, PractitionerApi, TemplateApi, DocumentApi, EvaluationApi } from 'apis';
import { NoteType, CompositionStatusType, CompositionType } from 'apis/medical';
import {
  useState,
  useUIStore,
  useForm,
  useAuth,
  useInvalidateNote,
  useZccSoapNotesQuery,
  useCopyToClipboard,
} from 'utils/hooks';
import Modal from '../templates/Modal';
import Grid from '@mui/material/Grid';
import { ToggleSection, Icon, Checkbox } from 'components';
import styles from './style.module.scss';
import ProviderNotesReviewExtensionRequest from './ProviderNotesReviewExtensionRequest';
import { ProviderNotesReviewProps, ExtensionRequestType, AdditionalNoteType } from './ProviderNotesReview.types';
import ProviderNotesReviewHeader from './ProviderNotesReviewHeader';
import ProviderNotesReviewAdditionalInformation from './ProviderNotesReviewAdditionalInformation';
import ProviderNotesAdditionalNote from './ProviderNotesAdditionalNote';
import { AutomatedReviewSettingsContent } from '../AutomatedReviewSettings';
import { toast } from 'react-toastify';
import { DuplicateCopyBoldIcon, ShieldInsuranceCheckedIcon } from '@zeel-dev/zeel-ui';
import { QuestionnaireResponseType } from 'apis/evaluation';
import uniq from 'lodash/uniq';

export default function ProviderNotesReview({ noteId, onClose, scrollTop, modalProps }: ProviderNotesReviewProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm();
  const { user } = useAuth();
  const { copyToClipboard } = useCopyToClipboard();

  const [error, setError] = useState<string>();
  const [extension, setExtension] = useState(ExtensionRequestType.MANUAL_APPROVED);
  const [addendumCount, setAddendumCount] = useState(0);
  const [instructionCount, setInstructionCount] = useState(0);
  const [isAnyEdited, setIsAnyEdited] = useState(false);
  const { invalidateNote } = useInvalidateNote(() => onClose?.(true), setError, scrollTop);

  const {
    loading,
    soapNotes,
    compareNotes,
    serviceRequest,
    patient,
    hasExtensionRequest,
    practitioner,
    appointment,
    facility,
  } = useZccSoapNotesQuery({
    noteId,
    needFacility: true,
    onPractitionerSuccess: (validatedProvider: boolean, automatedReviewThreshold: number) => {
      form.setFields({
        validatedProvider: validatedProvider,
        automatedReviewThreshold: validatedProvider ? automatedReviewThreshold || 5 : 5,
      });
    },
    onError: (err) => setError(err),
  });

  const durationUpdateRequest = (reason: string) =>
    hasExtensionRequest && serviceRequest?.id
      ? [
          MedicalApi.updateServiceRequest(serviceRequest.id, {
            duration_update: {
              duration_status: extension,
              requester_member_id: user?.memberId,
              ...(extension === ExtensionRequestType.REJECTED ? { rejection_reason: reason } : {}),
            },
          }),
        ]
      : [];

  const updateProviderAutomatedReviewRequest = () => {
    const updatedReviewByPassed = form.getField('validatedProvider');
    const updatedAutomatedReviewThreshold = parseInt(form.getField('automatedReviewThreshold'));
    return practitioner?.id &&
      (updatedReviewByPassed !== practitioner.validated_provider ||
        (practitioner.validated_provider &&
          practitioner.automated_review_threshold !== updatedAutomatedReviewThreshold))
      ? [
          PractitionerApi.updatePractitioner(practitioner.id, {
            validated_provider: !!updatedReviewByPassed,
            ...(updatedReviewByPassed ? { automated_review_threshold: updatedAutomatedReviewThreshold } : {}),
          }),
        ]
      : [];
  };

  const checkAnyEdited = () => {
    setIsAnyEdited((soapNotes?.notes || []).some((n: any) => !!form.getField(`field-${n.title}`)));
  };

  const approveNote = async (values: any = {}) => {
    if (!soapNotes) return;
    const isAmended = addendumCount > 0 || values.amendWithoutComments;

    try {
      await Promise.all([
        MedicalApi.updateSoapNote(soapNotes.id, {
          title: soapNotes?.title,
          status: isAmended ? CompositionStatusType.Amended : CompositionStatusType.Approved,
          procedure_id: soapNotes.procedure_id,
          reviewed_by: user?.pccAgentName,
          notes: (soapNotes?.notes || []).map((n: any) => {
            const value = (values || {})[`field-${n.title}`];
            return {
              title: n.title,
              text:
                addendumCount > 0 && value && !values.amendWithoutComments
                  ? `${n.text} [Administrative notation ${moment().format('L')}: ${value}]`
                  : n.text,
            };
          }),
        }),
        ...durationUpdateRequest(values.reasonForDeny),
        ...updateProviderAutomatedReviewRequest(),
      ]);

      openAlert({ title: `The soap was ${isAmended ? 'amended' : 'approved'}` });

      if (appointment && soapNotes) {
        if (soapNotes.milestone_appointment === 'first') {
          const uploadFirstAppointmentDocumentToHiOperator = async () => {
            const soapNoteBlob = await TemplateApi.combineSoapNotesAndEvaluations([soapNotes.id], [appointment.id]);
            const res = await DocumentApi.uploadDocumentToHiOperator(soapNoteBlob);
            const documentUrl = res.location;

            await MedicalApi.updateSoapNote(soapNotes.id, {
              document_url: documentUrl,
            });
          };

          toast.promise(
            uploadFirstAppointmentDocumentToHiOperator,
            {
              pending: 'Generating PDF and uploading to HiOperator...',
              success: {
                render: () => 'PDF successfully generated and uploaded to HiOperator',
                autoClose: 3000,
              },
              error: {
                autoClose: false,
                closeOnClick: false,
                render: () => (
                  <div>
                    <span>
                      The automatic generation of the documentation PDF was unsuccessful, please copy this message
                      (appointment ID {appointment.id} and composition ID {soapNotes.id}) send it to help@zeel.com, and
                      proceed with the documentation approval.
                    </span>
                    <DuplicateCopyBoldIcon
                      size={24}
                      onClick={() =>
                        copyToClipboard(`appointment ID ${appointment.id} and composition ID ${soapNotes.id}`)
                      }
                    />
                  </div>
                ),
              },
            },
            {
              position: 'bottom-right',
            }
          );
        } else if (soapNotes.milestone_appointment === 'final' && serviceRequest?.id) {
          let allSoapNotesIds: string[] = [];
          let appointmentIds: string[] = [];

          const uploadLastAppointmentDocumentToHiOperator = async () => {
            const serviceRequestId = serviceRequest.id;

            const [questionnaireResponses, allSoapNotes] = await Promise.all([
              EvaluationApi.searchQuestionnaireResponseByServiceRequestId(serviceRequestId),
              MedicalApi.fetchAllSoapNote(serviceRequestId),
            ]);

            allSoapNotesIds = allSoapNotes
              .sort(
                (a: CompositionType, b: CompositionType) =>
                  new Date(a.appointment_date).getTime() - new Date(b.appointment_date).getTime()
              )
              .map((soapNote) => soapNote.id);

            appointmentIds = uniq(
              questionnaireResponses
                .sort(
                  (a: QuestionnaireResponseType, b: QuestionnaireResponseType) => a.session_number - b.session_number
                )
                .map((questionnaireResponse) => questionnaireResponse.appointment_id)
            );

            const combinedBlob = await TemplateApi.combineSoapNotesAndEvaluations(allSoapNotesIds, appointmentIds);
            const res = await DocumentApi.uploadDocumentToHiOperator(combinedBlob);
            const documentUrl = res.location;

            await MedicalApi.updateSoapNote(soapNotes.id, {
              document_url: documentUrl,
            });
          };

          toast.promise(
            uploadLastAppointmentDocumentToHiOperator,
            {
              pending: 'Generating PDF and uploading to HiOperator...',
              success: {
                render: () => 'PDF successfully generated and uploaded to HiOperator',
                autoClose: 3000,
              },
              error: {
                autoClose: false,
                closeOnClick: false,
                render: () => (
                  <div>
                    <span>
                      The automatic generation of the documentation PDF was unsuccessful, please copy this message
                      (appointment IDs {appointmentIds.join(', ')} and composition IDs {allSoapNotesIds.join(', ')})
                      send it to help@zeel.com, and proceed with the documentation approval.
                    </span>
                    <DuplicateCopyBoldIcon
                      size={24}
                      onClick={() =>
                        copyToClipboard(
                          `appointment IDs ${appointmentIds.join(', ')} and composition IDs ${allSoapNotesIds.join(
                            ', '
                          )}`
                        )
                      }
                    />
                  </div>
                ),
              },
            },
            {
              position: 'bottom-right',
            }
          );
        }
      }

      onClose?.(true);
    } catch (e) {
      setError(`An error occurred while ${isAmended ? 'amending' : 'approving'} the note`);
      scrollTop();
      console.error(e);
    }
  };

  const rejectNote = async (values: any = {}) => {
    if (!soapNotes) return;
    const reason = (soapNotes.notes || [])
      .map(({ title }) => `${title.toUpperCase()} SECTION\n${values[`field-${title}`] || 'No edits required.'}`)
      .join('\n\n');

    try {
      await Promise.all([
        MedicalApi.updateSoapNote(soapNotes.id, {
          status: CompositionStatusType.Rejected,
          rejection_note: reason,
          reviewed_by: user?.pccAgentName,
        }),
        ...durationUpdateRequest(values.reasonForDeny),
      ]);

      openAlert({ title: `The soap was was rejected`, severity: 'info' });
      onClose?.(true);
    } catch (e) {
      setError('An error occurred while rejecting the request');
      scrollTop();
      console.error(e);
    }
  };

  const submitNote = async (values: any = {}) => {
    setError('');
    if (instructionCount > 0) {
      await rejectNote(values);
    } else {
      await approveNote(values);
    }
  };

  const handleInvalidateNote = () => {
    if (!soapNotes) return;

    invalidateNote(soapNotes.id);
  };

  const getNote = (notes: NoteType[] = [], title: string): NoteType | undefined => {
    return notes?.find((n: any) => n.title === title);
  };

  const hasPriorVersion = (title: string): boolean => {
    if (!compareNotes) return false;
    const prevNote = getNote(compareNotes.previous_version.notes, title);
    if (!prevNote) return false;
    const currentNote = getNote(compareNotes.current_version.notes, title);
    if (!currentNote) return false;
    return true;
  };

  const handleEdit = (addCount: number) => (additionalNoteType: AdditionalNoteType) => {
    if (additionalNoteType === AdditionalNoteType.ADDENDUM) {
      setAddendumCount((prev) => prev + addCount);
    } else {
      setInstructionCount((prev) => prev + addCount);
    }
    checkAnyEdited();
  };

  const handleExtensionChange = (ext: ExtensionRequestType): void => {
    setExtension(ext);
    if (ext === ExtensionRequestType.MANUAL_APPROVED) {
      form.deleteField('reasonForDeny');
    }
  };

  return (
    <Modal
      {...modalProps}
      loading={loading}
      alert={error}
      title={`Review Provider SOAP Notes${hasExtensionRequest ? ' + Extension Request' : ''}`}
      description={
        <>
          Please review this provider’s appointment notes. You may edit any of the fields prior to submission. We’ll
          keep a record of the original notes. Need help? Read the Process Overview
        </>
      }
      className={styles.base}
      footerClassName={styles.footer}
      backdropClose={false}
      actions={[
        {
          label:
            addendumCount > 0
              ? 'Amend SOAP Notes'
              : instructionCount > 0
              ? 'Reject & Send to Provider for Revision'
              : form.values.amendWithoutComments
              ? 'Amend SOAP Notes Without Comments'
              : 'Approve SOAP Notes',
          onClick: form.handleSubmit(submitNote),
          state: 'primary',
          disabled: (addendumCount > 0 || instructionCount > 0) && !isAnyEdited,
        },
        {
          label: (
            <>
              <Icon className={styles.icon} name='trash' />
              Invalidate SOAP Note
            </>
          ),
          state: 'danger',
          className: styles.invalidateButton,
          onClick: handleInvalidateNote,
        },
      ]}
      stackActions>
      <Grid className={styles.section} container spacing={3}>
        <ProviderNotesReviewHeader
          loading={loading}
          serviceRequest={serviceRequest}
          notes={{
            rejectionNote: compareNotes?.current_version?.rejection_note,
            noteStatus: soapNotes?.status,
            cptCode: soapNotes?.cpt_code,
            soapNoteHistory: soapNotes?.history,
          }}
          appointment={{
            practitioner,
            date: soapNotes?.appointment_date,
            timeSegment: appointment?.time_segment || appointment?.minutes_duration?.toString(),
            start: appointment?.start,
            sessionNumber: appointment?.sessionNumber,
          }}
          patient={patient}
          facility={facility}
        />
        {soapNotes?.zcc_entry && (
          <Grid item xs={12}>
            <div className={styles.info}>
              <ShieldInsuranceCheckedIcon size={24} />
              <p>
                This SOAP note was entered by internal staff via the ZCC. Please use the &quot;Amend&quot; feature and
                do not Reject the note. Contact Dr. Gallagher if any questions.
              </p>
            </div>
          </Grid>
        )}
        {soapNotes?.notes?.map(({ title, text }) => (
          <ProviderNotesAdditionalNote
            key={`field-${title}`}
            id={`field-${title}`}
            title={title}
            text={text}
            hasPriorVersion={hasPriorVersion(title)}
            priorNote={getNote(compareNotes?.previous_version?.notes, title)?.text}
            previousVersion={compareNotes?.previous_version}
            soapNotes={soapNotes}
            user={user}
            canAddAddendum={!form.values.amendWithoutComments && instructionCount === 0}
            canAddInstruction={addendumCount === 0}
            form={form}
            bind={bind}
            onStartEdit={handleEdit(1)}
            onFinishEdit={checkAnyEdited}
            onDeleteAdditionalNote={handleEdit(-1)}
          />
        ))}
        {soapNotes?.signatory_name && (
          <Grid item xs={12}>
            <p className={styles.signatureLabel}>Provider E-Signature</p>
            <p className={styles.signature}>{soapNotes.signatory_name}</p>
            <p className={styles.signatureLabel}>
              Provider Attested and Submitted{' '}
              {moment(soapNotes?.date_submitted).tz('America/New_York').format('MM/DD/YYYY hh:mma z')}
            </p>
            {practitioner && <p className={styles.signatureLabel}>NPI {practitioner?.npi}</p>}
          </Grid>
        )}
        <Grid item xs={12}>
          <ToggleSection title='Additional Information' disabled={loading}>
            <ProviderNotesReviewAdditionalInformation patient={patient} serviceRequest={serviceRequest} />
          </ToggleSection>
        </Grid>
        {hasExtensionRequest && (
          <Grid item xs={12}>
            <ProviderNotesReviewExtensionRequest
              extension={extension}
              onExtensionChange={handleExtensionChange}
              bind={bind}
            />
          </Grid>
        )}
        {practitioner && !addendumCount && !instructionCount && (
          <Grid item container xs={12}>
            <AutomatedReviewSettingsContent form={form} bind={bind} />
          </Grid>
        )}
        {addendumCount === 0 && instructionCount === 0 && (
          <Grid item container xs={12}>
            <Checkbox {...bind('amendWithoutComments')} label='Amend Without Comments' />
          </Grid>
        )}
      </Grid>
    </Modal>
  );
}
